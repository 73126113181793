import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const countryCodes = [
  { code: '+1', name: 'USA' },
  // { code: '+91', name: 'India' },
  // { code: '+44', name: 'UK' },
  // { code: '+61', name: 'Australia' },
  // { code: '+971', name: 'UAE' },
  // { code: '+81', name: 'Japan' },
  // { code: '+49', name: 'Germany' },
];

const extractPhoneDetails = (phoneNumber) => {
  if (!phoneNumber) return { countryCode: '+1', number: '' };
  const numberPart = phoneNumber.slice(-10);
  const codePart = phoneNumber.slice(0, -10) || '+1';
  return { countryCode: codePart, number: numberPart };
};

const UserModal = ({ item, onSave, onClose }) => {
  const { countryCode, number } = extractPhoneDetails(item.phoneNumber);
  const [updatedUser, setUpdatedUser] = useState({ ...item, countryCode, phoneNumber: number });
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsSuperAdmin(decodedToken.role === 'superadmin');
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsSuperAdmin(false);
      }
    } else {
      setIsSuperAdmin(false);
    }
  }, []);

  const validate = () => {
    let tempErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!emailRegex.test(updatedUser.email)) {
      tempErrors.email = 'Invalid email format';
    }
    if (!phoneRegex.test(updatedUser.phoneNumber)) {
      tempErrors.phoneNumber = 'Phone number must be 10 digits';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const updatedData = {
        ...updatedUser,
        phoneNumber: `${updatedUser.countryCode}${updatedUser.phoneNumber}`,
      };
      onSave(updatedData);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">First Name</label>
            <input
              type="text"
              name="firstName"
              value={updatedUser.firstName}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={updatedUser.lastName}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <div className="flex">
              <select
                name="countryCode"
                value={updatedUser.countryCode}
                onChange={handleChange}
                className="border border-gray-300 p-2"
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>{`${country.name} (${country.code})`}</option>
                ))}
              </select>
              <input
                type="text"
                name="phoneNumber"
                value={updatedUser.phoneNumber}
                onChange={handleChange}
                className="border border-gray-300 p-2 flex-1"
                required
              />
            </div>
            {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Resident Role</label>
            {isSuperAdmin ? (
              <select
                name="role"
                value={updatedUser.role}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full"
                required
              >
                <option value="resident">Resident</option>
                <option value="admin">Admin</option>
                <option value="superadmin">Super Admin</option>
              </select>
            ) : (
              <input
                type="text"
                name="role"
                value={updatedUser.role}
                className="border border-gray-300 p-2 w-full"
                readOnly
              />
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white p-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserModal;
