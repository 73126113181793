import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import Cookies from 'js-cookie'; // Import js-cookie

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null); // Add role state
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const COOKIE_NAME = 'token'; // Cookie name for storing JWT token

  useEffect(() => {
    const checkToken = () => {
      const token = Cookies.get(COOKIE_NAME); // Get token from cookies

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp > currentTime) {
            setIsAuthenticated(true);
            setRole(decodedToken.role); // Set role from decoded token
          } else {
            // Token expired
            logout();
          }
        } catch (error) {
          // Handle token decode errors
          logout();
        }
      } else {
        // No token present
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Set loading to false after check is complete
    };

    checkToken();
  }, [navigate]);

  const login = (token) => {
    Cookies.set(COOKIE_NAME, token, { expires: 1 }); // Set token in cookies with an expiration of 1 day
    const decodedToken = jwtDecode(token);
    // console.log('Decoded Token:', decodedToken);
    setIsAuthenticated(true);
    setRole(decodedToken.role); // Set role on login
    
    // Immediately navigate based on the decoded role instead of waiting for state to update
    if (decodedToken.role === 'superadmin') {
      navigate('/dashboard');
    } else if (decodedToken.role === 'admin') {
      navigate('/register-user');
    } else if (decodedToken.role === 'resident') {
      navigate('/');
    }
  };
  

  const logout = () => {
    Cookies.remove(COOKIE_NAME); // Remove token from cookies
    setIsAuthenticated(false);
    // setRole(null); // Clear the role
    // window.location.href = '/login/resident'; // Redirect to login page
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
