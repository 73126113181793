import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Updated import for jwt-decode
import Modal from '../modals/Modal'; // Assuming you have a Modal component
import Cookies from 'js-cookie'; // Import js-cookie

const countryCodes = [
  { code: '+1', name: 'USA' },
  // { code: '+91', name: 'India' },
  // { code: '+44', name: 'UK' },
  // { code: '+61', name: 'Australia' },
  // { code: '+971', name: 'UAE' },
  // { code: '+81', name: 'Japan' },
  // { code: '+49', name: 'Germany' },
];

const RegisterUserPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1'); // Default to USA
  const [password, setPassword] = useState('1234');
  const [userType, roleSelected] = useState('resident');
  const [_id, setPropertyId] = useState(''); // State for selected property ID
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // State for success message
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [showPropertyFields, setShowSuperAdminFields] = useState(false);
  const [properties, setProperties] = useState([]); // State for properties
  const [availableRoles, setAvailableRoles] = useState([]); // State for available roles
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [selectedIsSuperAdmin, setIsSuperAdmin] = useState(false); // State for superadmin indicator
  const [isAdminOrSuperAdmin, setIsAdminOrSuperAdmin] = useState(false); // State for admin indicator

  const token = Cookies.get('token'); // Retrieve token from cookies

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === 'superadmin') {
          setAvailableRoles(['resident', 'admin', 'superadmin']);
          setShowSuperAdminFields(true);
        } else if (decodedToken.role === 'admin') {
          setAvailableRoles(['admin', 'resident']);
          setPropertyId(decodedToken.PID); // Set property ID for admin
        } else {
          setAvailableRoles([]);
        }
        if (decodedToken.role === 'admin' || decodedToken.role === 'superadmin') {
          setIsAdminOrSuperAdmin(true);
        }
        else {
          setIsAdminOrSuperAdmin(false);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setError('Invalid token');
      }
    } else {
      console.log('No token found');
    }
  }, [token]);

  useEffect(() => {
    if (showPropertyFields) {
      // Fetch properties from the backend
      const fetchProperties = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/get-all`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setProperties(response.data || []);
        } catch (error) {
          console.error('Error fetching properties:', error);
        }
      };

      fetchProperties();
    }
  }, [showPropertyFields, token]);

  const handleUserTypeChange = (e) => {
    const decodedToken = jwtDecode(token);
    const selectedUserType = e.target.value;
    // console.log('Selected user type:', selectedUserType);
    roleSelected(selectedUserType);
    if (selectedUserType === 'superadmin') {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
    setShowSuperAdminFields(decodedToken.role === 'superadmin');
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Ensure phone number is 10 digits long and contains only numbers
    return /^\d{10}$/.test(phoneNumber);
  };

  const handleRegister = async (e) => {
    setError(''); // Clear any previous errors
    e.preventDefault();

    if (!firstName || !lastName || !email || !password) {
      setError('All fields are required');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number must be 10 digits long and contain only numbers');
      return;
    }

    const formattedPhoneNumber = `${countryCode}${phoneNumber}`;

    const decodedToken = jwtDecode(token);

    setLoading(true); // Start loading

    try {
      const requestData = {
        firstName,
        lastName,
        email,
        phoneNumber: formattedPhoneNumber,
        password,
        role: userType,
        _id: showPropertyFields ? _id : decodedToken.PID // Include _id if necessary
      };

      // eslint-disable-next-line
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/register`, requestData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      // console.log('Registration successful:', response.data);
      setSuccess('User successfully created'); // Set success message
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setPassword('1234');
      roleSelected('resident');
      setIsSuperAdmin(false);
      setPropertyId(''); // Reset property ID
      setIsModalOpen(true); // Show the modal
      setError(''); // Clear any previous errors

    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response?.data?.message || 'Registration failed');
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="mx-auto max-w-lg">
      <div className="bg-slate-100 border p-8 rounded-lg shadow-lg w-full">
        <h2 className="text-2xl font-bold text-center mb-6">
          Register {isAdminOrSuperAdmin ? 'User' : 'Resident'}
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {loading && (
          <div className="flex justify-center items-center mb-4">
            <span className="visually-hidden">Loading...</span>
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full" role="status">
            </div>
          </div>
        )}
        <form onSubmit={handleRegister}>
          {/* Form fields remain the same */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
              First Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstName"
              type="text"
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastName"
              type="text"
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
            <div className="flex">
              <select className="shadow border rounded-l me-2 ps-2" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                {countryCodes.map(({ code, name }) => (
                  <option key={code} value={code}>{`${name} (${code})`}</option>
                ))}
              </select>
              <input className="shadow border rounded-r w-full py-2 px-3" type="text" maxLength="10" placeholder="Enter 10 digit phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required pattern="\d{10}" title="Must be exactly 10 digits" />
            </div>
          </div>
          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
            />
          </div> */}
          {showPropertyFields && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="userType">
                  Select User Type
                </label>
                <select
                  id="userType"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={userType}
                  onChange={handleUserTypeChange}
                  required
                >
                  {availableRoles.length > 0 ? (
                    availableRoles.map((role) => (
                      <option key={role} value={role}>
                        {role === 'admin' ? 'Property Manager' : role.charAt(0).toUpperCase() + role.slice(1)}
                      </option>
                    ))
                  ) : (
                    <option value="">No roles available</option>
                  )}
                </select>
              </div>
              {!selectedIsSuperAdmin && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="_id">
                    Property
                  </label>
                  <select
                    id="_id"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={_id}
                    onChange={(e) => setPropertyId(e.target.value)}
                    required
                  >
                    <option value="">Select a Property</option>
                    {properties.map(property => (
                      <option key={property._id} value={property._id}>
                        {`${property.propertyName} (${property.propertyLocation})`}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            disabled={loading} // Disable button while loading
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} message={success} />
      )}
    </div>
  );
};

export default RegisterUserPage;
