import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const DevicesPage = ({ propertyFilter }) => {
    const token = Cookies.get('token');
    const [properties, setProperties] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceForm, setDeviceForm] = useState({});
    const [gateActionStatus, setGateActionStatus] = useState({}); // Object for gate action status by device

    useEffect(() => {
        const fetchProperties = async (propertyId = '') => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/device/get-all`, {
                    params: { property: propertyId },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProperties(response.data || []);
            } catch (error) {
                console.error('Error fetching properties:', error);
                setError('Failed to load properties');
            }
        };

        fetchProperties(propertyFilter);
    }, [propertyFilter, token]);

    const openDeleteModal = (device) => {
        setSelectedDevice(device);
        setShowDeleteModal(true);
    };

    const openEditModal = (device) => {
        setError('');
        setSelectedDevice(device);
        setDeviceForm({
            deviceName: device.deviceName,
            deviceLabel: device.deviceLabel,
            deviceType: device.deviceType,
            deviceIpAddress: device.deviceIpAddress,
        });
        setShowEditModal(true);
    };

    const handleFormChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setDeviceForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleUpdateDevice = async () => {
        if (!selectedDevice) return;

        if (
            !deviceForm.deviceName.trim() ||
            !deviceForm.deviceType.trim() ||
            !deviceForm.deviceIpAddress.trim()
        ) {
            setError('Please fill out all required fields before saving.');
            return;
        }

        axios.put(
            `${process.env.REACT_APP_API_URL}/api/device/${selectedDevice._id}`,
            deviceForm,
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((response) => {
                // Check if the response status is 200 or 201 for success
                if (response.status === 200 || response.status === 201) {
                    const updatedProperties = properties.map((property) => ({
                        ...property,
                        device: property.device.map((dev) =>
                            dev._id === selectedDevice._id ? { ...dev, ...deviceForm } : dev
                        ),
                    }));

                    setProperties(updatedProperties);
                    setShowEditModal(false);
                    setSelectedDevice(null);
                    setSuccessMessage('Device updated successfully');
                    setError('');
                    setTimeout(() => setSuccessMessage(''), 3000);
                } else {
                    // If the response status is not 200/201, set the error message
                    setError(response.data.message || `Failed to update device. Status code: ${response.status}`);
                }
            })
            .catch((err) => {
                // Handle the case where the axios request fails (network issues, etc.)
                if (err.response) {
                    // If the error has a response (i.e., server responded with an error)
                    setError(err.response.data.message || `Failed to update device. Status code: ${err.response.status}`);
                } else {
                    // Handle the case where no response was received (network error, etc.)
                    setError('Failed to update device. Please try again later.');
                }
            });

    };

    const isFormValid =
        deviceForm.deviceName?.trim() &&
        deviceForm.deviceType?.trim() &&
        deviceForm.deviceIpAddress?.trim();

    const handleDeleteDevice = async () => {
        if (!selectedDevice) return;
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/device/${selectedDevice._id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const updatedProperties = properties.map((property) => ({
                ...property,
                device: property.device.filter((dev) => dev._id !== selectedDevice._id),
            }));

            setProperties(updatedProperties);
            setShowDeleteModal(false);
            setSelectedDevice(null);
            setSuccessMessage('Device deleted successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            console.error('Error deleting device:', err);
            setError('Failed to delete device');
        }
    };

    const handleOpenGate = async (deviceId, propertyId, propertyName, deviceName) => {
        setGateActionStatus((prevStatus) => ({
            ...prevStatus,
            [deviceId]: { message: 'Opening...', isError: false },
        }));

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/device/trigger-relay`,
                { deviceId, propertyId},
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setGateActionStatus((prevStatus) => ({
                    ...prevStatus,
                    [deviceId]: { message: `${deviceName} (${propertyName}): Gate opened successfully!`, isError: false },
                }));
            } else {
                setGateActionStatus((prevStatus) => ({
                    ...prevStatus,
                    [deviceId]: { message: `${deviceName} (${propertyName}): ${response.message || 'Failed to open gate'}`, isError: true },
                }));
            }

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to open gate';
            console.error('Error opening gate:', errorMessage);

            setGateActionStatus((prevStatus) => ({
                ...prevStatus,
                [deviceId]: { message: `${deviceName} (${propertyName}): ${errorMessage}`, isError: true },
            }));
        }

        // Clear the status message after 3 seconds
        setTimeout(() => {
            setGateActionStatus((prevStatus) => ({
                ...prevStatus,
                [deviceId]: null,
            }));
        }, 3000);
    };


    return (
        <div className="container mx-auto px-6 py-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Devices by Property</h2>

            {successMessage && (
                <div className="p-2 mb-4 bg-green-200 text-green-800 border border-green-400 rounded">
                    {successMessage}
                </div>
            )}

            {properties.length === 0 ? (
                <p className="text-center text-lg text-gray-600">No properties or devices found.</p>
            ) : (
                properties.map((property) => (
                    <div key={property._id} className="mb-10 border border-gray-300 rounded-lg shadow-lg">
                        <div className="p-6 bg-gray-100 border-b">
                            <h3 className="text-2xl font-semibold text-gray-700">{property.propertyName}</h3>
                            <p className="text-sm text-gray-500">{property.propertyLocation}</p>
                        </div>
                        <div className="p-6">
                            {property.device?.length === 0 ? (
                                <p className="text-gray-500 text-center">No devices available for this property.</p>
                            ) : (
                                <table className="min-w-full border-collapse border border-gray-300">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="border border-gray-300 px-4 py-2 text-left">Device Name</th>
                                            <th className="border border-gray-300 px-4 py-2 text-left">Label</th>
                                            <th className="border border-gray-300 px-4 py-2 text-left">Type</th>
                                            <th className="border border-gray-300 px-4 py-2 text-left">IP Address</th>
                                            <th className="border border-gray-300 px-4 py-2 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {property.device.map((device) => (
                                            <tr key={device._id} className="bg-white">
                                                <td className="border border-gray-300 px-4 py-2">{device.deviceName}</td>
                                                <td className="border border-gray-300 px-4 py-2">{device.deviceLabel}</td>
                                                <td className="border border-gray-300 px-4 py-2">{device.deviceType}</td>
                                                <td className="border border-gray-300 px-4 py-2">{device.deviceIpAddress}</td>
                                                <td className="border border-gray-300 px-4 py-2 text-center">
                                                    <div className="flex justify-center gap-2">
                                                        {device.deviceType === 'gate' && (
                                                            <button
                                                                onClick={() => handleOpenGate(device._id, property._id, property.propertyName, device.deviceName)}
                                                                className="bg-green-500 text-white py-1 px-4 rounded-md hover:bg-green-600 transition-all"
                                                            >
                                                                Open Gate
                                                            </button>
                                                        )}
                                                        <button
                                                            onClick={() => openEditModal(device)}
                                                            className="bg-blue-500 text-white py-1 px-4 rounded-md hover:bg-blue-600 transition-all"
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            onClick={() => openDeleteModal(device)}
                                                            className="bg-red-500 text-white py-1 px-4 rounded-md hover:bg-red-600 transition-all"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                    {gateActionStatus[device._id] && (
                                                        <p
                                                            className={`mt-2 text-sm ${gateActionStatus[device._id].isError ? 'text-red-600' : 'text-green-700'
                                                                }`}
                                                        >
                                                            {gateActionStatus[device._id].message}
                                                        </p>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                ))
            )}

            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Edit Device</h3>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-4">
                                <label className="block text-gray-700">Device Name</label>
                                <input
                                    type="text"
                                    name="deviceName"
                                    value={deviceForm.deviceName || ''}
                                    onChange={handleFormChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Device Label</label>
                                <input
                                    type="text"
                                    name="deviceLabel"
                                    value={deviceForm.deviceLabel || ''}
                                    onChange={handleFormChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceType">
                                    Device Type
                                </label>
                                <select
                                    type="text"
                                    name="deviceType"
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                    value={deviceForm.deviceType || ''}
                                    onChange={handleFormChange}
                                    required
                                >
                                    <option value="">Select a Device Type</option>
                                    <option value="gate">Main Gate</option>
                                </select>
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700">Device IP Address</label>
                                <input
                                    type="text"
                                    name="deviceIpAddress"
                                    value={deviceForm.deviceIpAddress || ''}
                                    onChange={handleFormChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            {error && <p className="text-red-500 mb-6 text-center">{error}</p>}
                            <div className="flex justify-end gap-4">
                                <button
                                    onClick={() => setShowEditModal(false)}
                                    className="bg-gray-300 text-gray-700 py-1 px-4 rounded-md hover:bg-gray-400 transition-all"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleUpdateDevice}
                                    className={`py-1 px-4 rounded-md transition-all ${isFormValid ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                        }`}
                                    disabled={!isFormValid}
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Confirm Delete</h3>
                        <p className="text-gray-600 mb-6">
                            Are you sure you want to delete the device "{selectedDevice?.deviceName}"?
                        </p>
                        <div className="flex justify-between gap-4">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="bg-gray-300 text-gray-700 py-1 px-4 rounded-md hover:bg-gray-400 transition-all"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteDevice}
                                className="bg-red-500 text-white py-1 px-4 rounded-md hover:bg-red-600 transition-all"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DevicesPage;
