import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie'; // Import js-cookie
import logo from '../assets/logos/companylogo.png'; // Import the logo image
import axios from 'axios'; // Import axios for HTTP requests

const countryCodes = [
  { code: '+1', name: 'USA' },
];

const Headerqr = () => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle
  const [userRole, setUserRole] = useState(null); // State to store user role
  const [property, setProperty] = useState(null); // State to store property data
  const [user, setUser] = useState(null); // State to store user data
  const [emailNotif, setEmailNotif] = useState(false);
  const [smsNotif, setSmsNotif] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [countryCode, setCountryCode] = useState('+1'); // Default country code
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const token = Cookies.get('token'); // Retrieve token from cookies
    const fetchUserData = async () => {
      const decodeToken = jwtDecode(token);
      const userId = decodeToken.UID;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resident/get-by-id/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUser(response.data);
        setEmailNotif(response.data.preferences.email);
        setSmsNotif(response.data.preferences.sms);
      }
      catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    const fetchPropertyData = async (propertyId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/get-property-by-id`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: { propertyId }
        });
        setProperty(response.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    // Clear property data when logging out or accessing the email verification page
    if (currentPath === '/verify-email' || currentPath === '/reset-password' || currentPath.includes('set-new-password')) {
      logout();
      // Optionally reload the page
      // window.location.reload();
    } else {
      if (token) {
        fetchUserData();
        try {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken.role);

          if (decodedToken.PID && decodedToken.role !== 'Super-Admin') {
            fetchPropertyData(decodedToken.PID);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          setUserRole(null);
        }
      } else {
        setUserRole(null); // Ensure role is cleared if no token
        setProperty(null); // Clear property data when no token is found
      }
    }
  }, [isAuthenticated, currentPath, logout]);

  const handleNotificationChange = async (type, newValue) => {
    const token = Cookies.get('token'); // Retrieve token from cookies
    const decodeToken = jwtDecode(token);
    const userId = decodeToken.UID;

    if (type === 'sms' && newValue === true) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resident/phone-number-check/${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.data.available) {
        setIsPhoneModalOpen(true); // Open the modal if phone number is missing
        return;
      }
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/resident/update-preferences/${userId}`,
        {
          email: type === 'email' ? newValue : emailNotif,
          sms: type === 'sms' ? newValue : smsNotif,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update state based on the type
      if (type === 'email') {
        setEmailNotif(newValue);
      } else if (type === 'sms') {
        setSmsNotif(newValue);
      }
    } catch (error) {
      console.error('Error updating notification preference:', error);
    }
  };

  const handlePhoneSubmit = async () => {
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    if (!phoneNumber.match(/^\d{10}$/)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      const token = Cookies.get('token');
      const decodeToken = jwtDecode(token);
      const userId = decodeToken.UID;

      await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/update-phone-number/${userId}`,
        { phoneNumber: fullPhoneNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setIsPhoneModalOpen(false);

      // Now enable SMS notification only after successful phone number update
      await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/update-preferences/${userId}`,
        { sms: true, email: emailNotif },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setSmsNotif(true);
    } catch (error) {
      console.error('Error updating phone number:', error);
    }
  };

  // Function to handle logout and reset the property state
  const handleLogout = () => {
    logout();
    setProperty(null); // Reset the property state when logging out
  };

  // Function to determine if the link is active
  const isActive = (path) => currentPath === path ? 'bg-gray-300' : '';

  return (
    <header className="bg-slate-100 rounded-lg shadow-xl text-black p-4 m-4 z-40">
      <nav className="container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center relative">
        {/* Logo and Property Info */}
        <div className="flex items-center lg:justify-start w-full">
          <img src={logo} alt="Logo" className="w-20 h-20 mr-4" /> {/* Logo */}
          <div className="text-lg font-bold">
            {property ? (
              <div>
                <div>{property.propertyName}</div>
                <div className="text-sm text-gray-600">{property.propertyLocation}</div>
              </div>
            ) : (
              <div>
                {'Bay Business Edge LLC'}
              </div>
            )}
          </div>
          {/* Mobile Menu Button */}
          {isAuthenticated && (
            <button
              onClick={() => setIsMenuOpen((prev) => !prev)}
              className="lg:hidden text-black hover:text-gray-500 transition duration-300 ml-auto"
            >
              {isMenuOpen ? '✖' : '☰'} {/* Toggle icon */}
            </button>
          )}
        </div>

        {/* Navigation Links */}
        <ul
          className={`lg:flex lg:space-x-6 space-y-4 lg:space-y-0 w-full lg:w-auto mt-4 lg:mt-0 ${
            isMenuOpen ? 'block' : 'hidden'
          } lg:flex lg:items-center bg-gray-200 lg:bg-transparent p-4 lg:p-0 absolute lg:static top-full left-0 w-full lg:w-auto shadow-lg lg:shadow-none`}
        >
          {isAuthenticated && (userRole === 'superadmin' || userRole === 'admin') && (
            <>
              <li className={`rounded-md ${isActive('/dashboard')}`}>
                <Link
                  to="/dashboard"
                  onClick={() => {
                    setIsProfileOpen(false);
                    setIsMenuOpen((prev) => !prev);
                  }}
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Admin Dashboard
                </Link>
              </li>
              <li className={`rounded-md ${isActive('/bulk-user-upload')}`}>
                <Link
                  to="/bulk-user-upload"
                  onClick={() => {
                    setIsProfileOpen(false);
                    setIsMenuOpen((prev) => !prev);
                  }}
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Bulk Registration
                </Link>
              </li>
              {isAuthenticated && (userRole === 'superadmin') && (
                <>
                  <li className={`rounded-md ${isActive('/register-property')}`}>
                    <Link
                      to="/register-property"
                      onClick={() => {
                        setIsProfileOpen(false);
                        setIsMenuOpen((prev) => !prev);
                      }}
                      className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                    >
                      Register Property
                    </Link>
                  </li>
                  <li className={`rounded-md ${isActive('/register-device')}`}>
                    <Link
                      to="/register-device"
                      onClick={() => {
                        setIsProfileOpen(false);
                        setIsMenuOpen((prev) => !prev);
                      }}
                      className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                    >
                      Register Device
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
          {(isAuthenticated && userRole !== 'resident') && (
            <li className={`rounded-md ${isActive('/register-user')}`}>
              <Link
                to="/register-user"
                onClick={() => {
                  setIsProfileOpen(false);
                  setIsMenuOpen((prev) => !prev);
                }}
                className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
              >
                Register {userRole !== 'superadmin' ? 'Resident' : 'User'}
              </Link>
            </li>
          )}
          {isAuthenticated && (
            <>
              <li className={`rounded-md ${isActive('/')}`}>
                <Link
                  to="/"
                  onClick={() => {
                    setIsProfileOpen(false);
                    setIsMenuOpen((prev) => !prev);
                  }}
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Register Visitor
                </Link>
              </li>
              {userRole === 'resident' && (
                <>
                  <li className={`rounded-md ${isActive('/visitor-list')}`}>
                    <Link
                      to="/visitor-list"
                      onClick={() => {
                        setIsProfileOpen(false);
                        setIsMenuOpen((prev) => !prev);
                      }}
                      className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                    >
                      Visitor List
                    </Link>
                  </li>
                  <li className={`rounded-md ${isActive('/access-gates')}`}>
                    <Link
                      to="/access-gates"
                      onClick={() => {
                        setIsProfileOpen(false);
                        setIsMenuOpen((prev) => !prev);
                      }}
                      className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                    >
                      Gates
                    </Link>
                  </li>
                </>
              )}
              {userRole === 'admin' && (
                <li className={`rounded-md ${isActive('/access-gates')}`}>
                  <Link
                    to="/access-gates"
                    onClick={() => {
                      setIsProfileOpen(false);
                      setIsMenuOpen((prev) => !prev);
                    }}
                    className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                  >
                    Access Gates
                  </Link>
                </li>
              )}
              <ul className={`lg:flex ${isMenuOpen ? 'block' : 'hidden'} lg:items-center bg-gray-200 lg:bg-transparent pb-2 lg:p-0`}>
                {isAuthenticated && (
                  <li className="relative">
                    <button onClick={() => setIsProfileOpen(!isProfileOpen)} className="ps-2">
                      <span className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start">Profile</span>
                    </button>
                    {isProfileOpen && (
                      <div className="absolute right-0 mt-2 w-64 p-4 bg-white shadow-lg rounded-xl z-50">
                        <div className="mb-4">
                          <p className="font-semibold">{user?.firstName} {user?.lastName}</p>
                          <p className="text-sm text-gray-500">{user?.email}</p>
                          <p className="text-sm text-gray-500">{user?.phoneNumber}</p>
                        </div>
                        <div className="border-t pt-2">
                          <p className="font-semibold mb-2">Notifications</p>
                          <div className="flex items-center justify-between mb-2">
                            <span>Email</span>
                            <input
                              type="checkbox"
                              checked={emailNotif}
                              onChange={() => handleNotificationChange('email', !emailNotif)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span>SMS</span>
                            <input
                              type="checkbox"
                              checked={smsNotif}
                              onChange={() => handleNotificationChange('sms', !smsNotif)}
                              disabled
                            />
                          </div>
                        </div>
                        <button
                          onClick={handleLogout}
                          className="text-white bg-red-500 hover:bg-red-600 font-semibold py-1 px-4 mt-2 rounded-lg transition duration-300 shadow-md flex items-center justify-center"
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </li>
                )}
              </ul>
            </>
          )}
        </ul>
      </nav>
      {isPhoneModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
            <h2 className="text-lg font-bold mb-4">Enter Your Phone Number</h2>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
              <div className="flex">
                <select
                  className="shadow border rounded-l px-2 py-2"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  {countryCodes.map(({ code, name }) => (
                    <option key={code} value={code}>{`${name} (${code})`}</option>
                  ))}
                </select>
                <input
                  className="shadow border rounded-r w-full py-2 px-3"
                  type="text"
                  maxLength="10"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  pattern="\d{10}"
                  title="Must be exactly 10 digits"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={() => setIsPhoneModalOpen(false)}>Cancel</button>
              <button className="px-4 py-2 bg-blue-500 text-white rounded-md" onClick={handlePhoneSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}

    </header>
  );
};

export default Headerqr;
