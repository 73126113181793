import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../modals/BulkModal';
import Cookies from 'js-cookie';
import Papa from 'papaparse';
import { jwtDecode } from 'jwt-decode';

const BulkUserUpload = () => {
  const [users, setUsers] = useState([{ firstName: '', lastName: '', email: '', role: 'resident', propertyId: '', phoneNumber: '' }]);
  const [properties, setProperties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminPropertyId, setAdminPropertyId] = useState('');
  const [file, setFile] = React.useState(null);
  const [copiedIds, setCopiedIds] = useState({}); // For tracking copied IDs
  const [loading, setLoading] = useState(false); // Loading state

  const token = Cookies.get('token');

  useEffect(() => {
    if (!token) return; // Return early if there's no token

    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error('Failed to decode token:', error);
      return;
    }

    // Check if user is admin
    if (decodedToken.role === 'admin') {
      setIsAdmin(true);
      setAdminPropertyId(decodedToken.PID);
    }

    // Fetch properties if the user is super admin or appropriate role
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`/api/property/get-all`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setProperties(response.data);
      } catch (err) {
        console.error('Failed to fetch properties:', err);
      }
    };
    fetchProperties();
  }, [token]);


  useEffect(() => {
    const checkFormValidity = () => {
      console.log(users.length);
      var isValid = true;
      if (users.length !== 0) {
        isValid = users.every(user => {
          const emailError = validateEmail(user.email);
          const phoneError = validatePhoneNumber(user.phoneNumber);

          user.emailError = emailError;
          user.phoneError = phoneError;

          return !phoneError && !emailError && user.firstName && user.lastName && user.role && (isAdmin || user.propertyId);
        });
        setIsFormValid(isValid);
      }
      else {
        setIsFormValid(false);
      }
    };
    checkFormValidity();
  }, [users, isAdmin]);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) ? null : "Invalid email address.";
  };

  // ValidatePhoneNumber function to check 10-digit phone number or allow empty input.
  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === '') return null; // Allow empty input

    const phoneWithoutCode = phoneNumber.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
    if (phoneWithoutCode.length !== 10) return "Phone number must be exactly 10 digits."; // Check for exactly 10 digits.

    return null;
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const values = [...users];
    values[index].phoneError = null;
    values[index].emailError = null;

    if (name === "email") {
      const emailError = validateEmail(value);
      values[index].emailError = emailError;
    }

    if (name === "phoneNumber") {
      const phoneError = validatePhoneNumber(value);
      values[index].phoneError = phoneError;
    }

    values[index][name] = value;

    // Assign role and propertyId if admin
    if (isAdmin) {
      values[index].role = 'resident';
      values[index].propertyId = adminPropertyId;
    }

    setUsers(values);
  };

  const handleAddRow = () => {
    setUsers([...users, { firstName: '', lastName: '', email: '', phoneNumber: '', role: 'resident', propertyId: isAdmin ? adminPropertyId : '' }]);
  };

  const handleDeleteRow = (index) => {
    const values = [...users];
    values.splice(index, 1);
    setUsers(values);
  };

  const handleUpload = async () => {
    setLoading(true); // Set loading to true when starting upload
    setErrorDetails([]); // Clear any previous error details
    setModalMessage(''); // Clear any previous modal message
    try {
      // Before uploading, append +1 to all phone numbers
      const updatedUsers = users.map(user => {
        const cleanedPhone = user.phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        return {
          ...user,
          phoneNumber: cleanedPhone ? `+1${cleanedPhone}` : '' // Add +1 only if phoneNumber is not empty
        };
      });

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/bulk-upload`, { users: updatedUsers }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const { successfulUploads, failedUploads, errors } = response.data;

      if (failedUploads > 0) {
        setModalMessage(`Bulk upload completed with ${successfulUploads} successful uploads and ${failedUploads} failed uploads`);
        setErrorDetails(errors);
      } else {
        setModalMessage('Bulk upload completed successfully');
      }

      setIsModalOpen(true);
    } catch (err) {
      console.error('Upload failed:', err);
      setModalMessage('Upload failed or error occurred');
      setIsModalOpen(true);
      setErrorDetails([]);
    } finally {
      setLoading(false); // Set loading to false after upload is complete
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDownloadTemplate = () => {
    let headerRow;
    if (isAdmin) {
      headerRow = ['firstName', 'lastName', 'email', 'phoneNumber'];
    }
    else {
      headerRow = ['firstName', 'lastName', 'email', 'phoneNumber', 'role', 'propertyId'];
    }
    const csvContent = [headerRow.join(',')].join('\n');

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'user_template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFile(file);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const updatedUsers = results.data.map(user => {
          let formattedPhone = user.phoneNumber.trim();

          const error = validatePhoneNumber(formattedPhone);

          return {
            ...user,
            phoneNumber: formattedPhone,
            phoneError: error,
            role: isAdmin ? 'resident' : (user.role?.toLowerCase() === 'property manager' ? 'admin' : user.role?.toLowerCase()),
            propertyId: isAdmin ? adminPropertyId : user.propertyId,
          };
        });

        setUsers(updatedUsers);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      }
    });
  };

  const handleClearFile = () => {
    setFile(null);
    setUsers([]); // Clear the users data if necessary
    document.getElementById('file-input').value = ''; // Clear the file input value
  };

  const handleCopyToClipboard = async (text, id) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(`Copied to clipboard: ${text}`);

      // Change button state to "Copied"
      setCopiedIds((prev) => ({ ...prev, [id]: true }));

      // Reset button state after 3 seconds
      setTimeout(() => {
        setCopiedIds((prev) => ({ ...prev, [id]: false }));
      }, 3000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className="mx-auto">
      <div className="bg-white border rounded-lg p-4">
        <h2 className="text-2xl font-bold text-center mb-6">Bulk User Creation</h2>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 me-3"
          onClick={handleDownloadTemplate}
        >
          Download CSV Template
        </button>

        <input
          id="file-input"
          type="file"
          accept=".csv"
          onChange={handleCSVUpload}
          className="mb-4 me-3 p-1 border border-black rounded"
        />

        {file && (
          <button
            onClick={handleClearFile}
            className="text-red-500 hover:text-red-700 font-bold text-xl mb-4 me-3"
          >
            &times; {/* This is a close symbol (×) */}
            remove file
          </button>
        )}

        {!isAdmin && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            onClick={handleAddRow}
          >
            Add User
          </button>
        )}

        <table className="w-full mb-4">
          <thead className='border-b border-surface bg-surface-light text-sm font-medium text-foreground dark:bg-surface-dark'>
            <tr>
              <th className='border border-black'>First Name</th>
              <th className='border border-black'>Last Name</th>
              <th className='border border-black'>Email</th>
              <th className='border border-black'>Phone Number</th>
              <th className='border border-black'>Role</th>
              {!isAdmin && (
                <th className='border border-black'>Property</th>
              )}
              <th className='border border-black'>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td className='table-fixed text-center border border-black'><input type="text" name="firstName" value={user.firstName} onChange={e => handleInputChange(index, e)} autoComplete="off" /></td>
                <td className='table-fixed text-center border border-black'><input type="text" name="lastName" value={user.lastName} onChange={e => handleInputChange(index, e)} autoComplete="off" /></td>
                <td className='table-fixed text-center border border-black'><input type="email" name="email" value={user.email} onChange={e => handleInputChange(index, e)} autoComplete="off" />
                  {user.emailError && <p className="text-red-500 text-sm">{user.emailError}</p>}
                </td>
                <td className="table-fixed text-center border border-black">
                  <input
                    type="text"
                    name="phoneNumber"
                    value={user.phoneNumber}
                    onChange={e => handleInputChange(index, e)}
                    placeholder="10 digit Phone No"
                    autoComplete="off"
                    className={`border ${user.phoneError ? 'border-red-500' : 'border-black'}`}
                  />
                  {user.phoneError && <p className="text-red-500 text-sm">{user.phoneError}</p>}
                </td>
                <td className='table-fixed text-center border border-black'>
                  <select name="role" value={user.role} onChange={e => handleInputChange(index, e)} required>
                    <option value="resident">Resident</option>
                    {!isAdmin && (<option value="admin">Property Manager</option>)}
                  </select>
                </td>
                {!isAdmin && (
                  <td className='table-fixed text-center border border-black'>
                    <select name="propertyId" value={user.propertyId} onChange={e => handleInputChange(index, e)} required>
                      <option value="">Select Property</option>
                      {properties.map(property => (
                        <option key={property._id} value={property._id}>{property.propertyName}</option>
                      ))}
                    </select>
                  </td>
                )}
                <td className='table-fixed text-center border border-black'>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    onClick={() => handleDeleteRow(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${loading || !isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleUpload}
          disabled={loading || !isFormValid}
        >
          {loading ? 'Creating Users...' : 'Create Users'}
        </button>

        {!isAdmin && (
          <div className="mb-4 mt-4">
            <h3 className="text-xl font-bold mb-2">Property and Role Details</h3>
            <div className="mb-4">
              <h4 className="text-lg font-semibold">Properties</h4>
              <table className="w-1/3 border-collapse">
                <thead>
                  <tr>
                    <th className="border border-black p-2 text-center">Property Name</th>
                    {/* <th className="border border-black p-2 text-center">Property ID</th> */}
                    <th className="border border-black p-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {properties.map(property => (
                    <tr key={property._id}>
                      <td className="border border-black p-2 text-center">{property.propertyName}</td>
                      <td className="border border-black p-2 text-center">
                        <button
                          className={`py-1 px-2 rounded font-bold ${copiedIds[property._id] ? 'bg-green-400' : 'bg-gray-200 hover:bg-gray-400'
                            } text-black`}
                          onClick={() => {
                            handleCopyToClipboard(`${property._id}`, property._id);
                          }}
                        >
                          {copiedIds[property._id] ? 'Copied!' : 'Copy'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mb-4">
              <h4 className="text-lg font-semibold">Allowed Roles</h4>
              <table className="w-1/3 border-collapse">
                <thead>
                  <tr>
                    <th className="border border-black p-2 text-center">Role</th>
                    <th className="border border-black p-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {['resident', 'admin'].map(role => {
                    const roleKey = role === 'admin' ? 'Property Manager' : 'Resident';
                    const copyValue = role === 'admin' ? 'Property Manager' : 'Resident'; // Determine the value to copy based on the role

                    return (
                      <tr key={role}>
                        <td className="border border-black p-2 text-center">{roleKey}</td>
                        <td className="border border-black p-2 text-center">
                          <button
                            className={`py-1 px-2 rounded font-bold ${copiedIds[role] ? 'bg-green-400' : 'bg-gray-200 hover:bg-gray-400'
                              } text-black`}
                            onClick={() => {
                              handleCopyToClipboard(copyValue, role); // Use copyValue to determine what to copy
                            }}
                          >
                            {copiedIds[role] ? 'Copied!' : 'Copy'}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        message={modalMessage}
        errorDetails={errorDetails}
      />
    </div>
  );
};

export default BulkUserUpload;
